import React, { useEffect, useRef, useState } from 'react'
import { convertDateAndTimeToLocal, getEventInfo, imageUrlCheck } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import LogoutAllModal from '../../Components/Modals/LogoutAllModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { authenticate2, getUserSubscription } from '../../network/service';
import { getUserSubscriptionData } from '../../Redux/UserSubscriptionDetails/userSubscriptionSlice';
import { getAccessToken } from '../../Redux/AToken/ATokenSlice';
import { getUser } from '../../Redux/UserSlice/UserSlice';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as Closeicon } from "../../assets/Icons/closeFilled.svg";
import Loading from '../../Components/Loading/Loading';
import { getVideoSubscription } from "../Subscription/service";
import Translate from '../../Components/Multilanguage/Translate';
import { toast } from 'react-toastify';

const EventDetailsScreen = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const thumbnailOrientation = projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [eventDetails, setEventDetails] = useState(false)
  const [logoutModal, setLogoutModal] = useState(false)
  const [isReport, setIsReport] = useState(false)
  const [isShareActive, setIsShareActive] = useState(false)
  const [subscribeButton, setSubscribeButton] = useState(false)
  const [isSubscribedUser, setIsSubscribedUser] = useState();
  const [loading, setLoading] = useState(true);
  const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState([]);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [pageURL, setPageURL] = useState("");


  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventId } = useParams()
  let shareRef = useRef(null);
  let reportRef = useRef(null);
  let shareIconRef = useRef(null);
  let shareTwoRef = useRef(null);
  let reportTwoRef = useRef(null);
  let shareIconTwoRef = useRef(null);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    window.scroll(0, 0)
    setPageURL(window.location.href);

  }, []);

  useEffect(() => {
    if (eventId) {
      if (eventId !== "null" && eventId !== "undefined") {
        const fetchApiCalls = async () => {
          await fetchUserSubscriptionDetails();
          await fetchEventInfo()

        }
        fetchApiCalls()
      }
    }
  }, [eventId]);


  useEffect(() => {
    if (eventDetails) {
      if (Object.entries(eventDetails).length > 0) {
        if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
          if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
            let isSubscribedUser;
            if (eventDetails?.subscriptions?.length > 0) {
              isSubscribedUser = userSubscriptionData?.data?.length > 0
                ? subscriptionCheck(userSubscriptionData?.data, eventDetails?.subscriptions)
                : false;
              if (isSubscribedUser === true) {
                setSubscribeButton(false)
                setIsSubscribedUser(true)
              } else {
                setIsSubscribedUser(false)
                setInitialVideoSubscriptions(eventDetails?.subscriptions)
                if (eventDetails?.free_event === true) {
                  setSubscribeButton(false)
                } else {
                  setSubscribeButton(true)
                }
              }
            } else {
              setSubscribeButton(false)
              setIsSubscribedUser(true)
            }
          } else {
            setSubscribeButton(false)
            setIsSubscribedUser(true)
          }
        } else {
          if (userSubscriptionData?.login_needed === false) {
            setSubscribeButton(false)
            setIsSubscribedUser(true)
          }
        }
      }
    }
  }, [eventDetails])

  useEffect(() => {
    let handleOutClick = (e) => {
      if (
        !shareIconRef?.current?.contains(e.target) &&
        !shareRef?.current?.contains(e.target) &&
        !shareIconTwoRef?.current?.contains(e.target) &&
        !shareTwoRef?.current?.contains(e.target)
      ) {
        setIsShareActive(false);
      }
      if (!reportRef?.current?.contains(e.target) && !reportTwoRef?.current?.contains(e.target)) {
        setIsReport(false);
      }
    };
    window.addEventListener("click", handleOutClick);
  }, [shareRef, reportRef, shareTwoRef, reportTwoRef]);

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };

  const redirectToLogin = () => {
    dispatch(
      getUser({
        user: null,
      })
    );
    localStorage.removeItem("userId");
    tokenAuthenticate();
    navigate("/login", {
      state: { path: location?.pathname, showId: eventId },
    });
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate2(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
    }
  };

  const handleSubscribe = (data) => {
    if (user) {
      localStorage.setItem("selectedSubId", data?.subscription_id);
      localStorage.setItem("selectedAmount", data?.price);
      localStorage.setItem("eventId", eventDetails?.event_id);
      localStorage.setItem("vanityUrl", eventId);
      localStorage.setItem("eventType", eventDetails?.type);
      navigate("/payment", { state: { subscription: { ...data, is_coupon: false } } });
    } else {
      navigate("/login", {
        state: { path: "/event/" + eventId, showId: eventId },
      });
    }
  };

  const fetchEventSubscription = async (eventDetails) => {
    try {
      const type = "event";
      const videoSubscriptionRespone = await getVideoSubscription(appInfo, eventDetails?.event_id, type);
      if (videoSubscriptionRespone?.status === 200) {
        return videoSubscriptionRespone?.data?.data;
      }
    } catch (err) { }
  };
  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };
  const watchVideoHandler = async () => {
    if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
      subscriptionCheckAndVideoPlay();
    } else {
      if (userSubscriptionData?.login_needed === false) {
        playVideo();
      } else if (userSubscriptionData?.login_needed === true) {
        setModalType({ type: "videoWatchLimitExpired" });
        setLogoutModal(true);
      } else {
        subscriptionCheckAndVideoPlay();
      }
    }
  };
  const subscriptionCheckAndVideoPlay = async () => {
    if (user) {
      if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
        if (userSubscriptionData?.forcibleLogout === true) {
          setLogoutModal(true);
          setModalType({
            type: "logoutAll",
            heading: "You’ve reached the maximum Device limit.Do you want to logout from all devices",
          });
        } else if (userSubscriptionData?.session_expired === true) {
          setModalType({ type: "redirect" });
          setLogoutModal(true);
          setTimeout(() => {
            redirectToLogin();
          }, 1000);
        } else {
          if (eventDetails?.subscriptions?.length > 0) {

            let isSubscribedUser;
            const res = await fetchEventSubscription(eventDetails)
            isSubscribedUser =
              userSubscriptionData?.data?.length > 0
                ? subscriptionCheck(userSubscriptionData?.data, res)
                : false;

            if (isSubscribedUser === true) {
              playVideo();
            } else {
              if (eventDetails?.free_event === true) {
                playVideo();
              } else {
                localStorage.setItem("eventId", eventDetails?.event_id);
                localStorage.setItem("vanityUrl", eventId);
                localStorage.setItem("eventType", eventDetails?.type);

                navigate("/subscription", {
                  state: { eventId: eventDetails?.event_id },
                });
              }
            }
          } else {
            if (eventDetails) {
              playVideo();
            }
          }
        }
      } else {
        playVideo();
      }
    } else {
      navigate("/login", {
        state: { path: "/event/" + eventId, showId: eventId },
      });
    }
  };
  const playVideo = async () => {
    if (eventDetails?.type !== "UPCOMING") {
      navigate("/player",
        { state: { eventId: eventDetails?.event_id } }
      );
    } else {
      toast.error("Event is not started yet")
    }

  };

  const fetchEventInfo = async () => {
    try {
      const response = await getEventInfo(appInfo, eventId);
      setEventDetails(response?.data?.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const showDetailsRedirect = () => {
    navigate(`/show-details/${eventDetails?.vanity_url}`)

  }
  if (loading) {
    return (<Loading />)
  }

  return (
    <div className='eventDetailsScreen'>
      {logoutModal && (
        <LogoutAllModal
          showId={eventId}
          appInfo={appInfo}
          setLogoutModal={setLogoutModal}
          modalType={modalType}
          //   videoDetails={selectedVideoDetails}
          subscriptionCheckAndVideoPlay={subscriptionCheckAndVideoPlay}
        />
      )}
      <div className="wrapper">
        <div className="bgImageContainer">
          {imageUrlCheck(eventDetails?.thumbnail_350_200) ? (
            <img
              src={`${eventDetails?.thumbnail_350_200}`}
              alt="Background"
            />
          ) : (
            <img
              src={
                `${process.env.REACT_APP_IMAGE_URL}${eventDetails?.thumbnail_350_200}`
              }
              alt="Background"
            />
          )}
          <div className="gradient"></div>
        </div>

        <div className="details">
          <div className="leftContainer">
            <div className="showCardDetails">
              <div className="imageContainer">
                {imageUrlCheck(thumbnailOrientation === "PORTRAIT" ? eventDetails?.thumbnail : eventDetails?.thumbnail_350_200) ? (
                  <img
                    src={thumbnailOrientation === "PORTRAIT" ? `${eventDetails?.thumbnail}` : `${eventDetails?.thumbnail_350_200}`}
                    alt="Background"
                  />
                ) : (
                  <img
                    src={
                      thumbnailOrientation === "PORTRAIT"
                        ? `${process.env.REACT_APP_IMAGE_URL}${eventDetails?.thumbnail}`
                        : `${process.env.REACT_APP_IMAGE_URL}${eventDetails?.thumbnail_350_200}`
                    }
                    alt="Background"
                  />
                )}
              </div>
              {
                subscribeButton && eventDetails?.type !== "ENDED" ? (
                  <div className="watchNow" onClick={() => watchVideoHandler()}>
                    <button><Translate textKey={'subscribe'} /></button>
                  </div>

                ) : eventDetails?.type !== "UPCOMING" && eventDetails?.type !== "ENDED" && (

                  <div className="watchNow" onClick={() => watchVideoHandler()}>
                    <button>{subscribeButton ? <Translate textKey={'subscribe'} /> : <Translate textKey={'watch_now'} />}</button>
                  </div>
                )
              }


              <div className="buttons">
                <div className="buttonContainerOne">
                  <button
                    className="share"
                    onClick={() => {
                      setIsShareActive(!isShareActive);
                      setIsReport(false);
                    }}
                    ref={shareIconRef}
                  >
                    <Translate textKey={'share'} />
                  </button>
                  <div className={isShareActive ? "active shareContainer" : "shareContainer"} ref={shareRef}>
                    <div className="facebookContainer" onClick={() => setIsShareActive(false)}>
                      {/* <div className="left"> */}
                      <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                        <Translate textKey={'facebook'} />
                        <div className="icon">
                          <FacebookIcon />
                        </div>
                      </FacebookShareButton>
                      {/* </div> */}
                      {/* <div className="right">
                        <FacebookIcon />
                      </div> */}
                    </div>
                    <div className="twitterContainer" onClick={() => setIsShareActive(false)}>
                      <TwitterShareButton url={pageURL} className="twitter">
                        <Translate textKey={'twitter'} />
                        <div className="icon">
                          <TwitterIcon />
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>
                <div className="buttonContainerTwo">
                  <button
                    className="more"
                    onClick={() => {
                      setIsReport(!isReport);
                      setIsShareActive(false);
                    }}
                    ref={reportRef}
                  >
                    {isReport ? <Closeicon /> : "..."}
                  </button>
                  {isReport ? (
                    <div
                      className={isReport ? "reportProblemContainer active" : "reportProblemContainer"}
                      onClick={() => navigate("/contact-support")}
                    >
                      <span><Translate textKey={'report_problem'} /></span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="rightContainer">
            <div className="showMetadata">
              <h1 className="name">{eventDetails?.event_name}</h1>
              <div className="data">
                <span className="duration">{convertDateAndTimeToLocal(eventDetails?.schedule_time)}</span>
              </div>
              <div className="genres">
                {eventDetails?.categories?.map((item, index) => (
                  <span key={index}>{item?.category_name}</span>
                ))}
              </div>

              {
                subscribeButton && eventDetails?.type !== "ENDED" ? (
                  <div className="watchNow" onClick={() => watchVideoHandler()}>
                    <button><Translate textKey={'subscribe'} /></button>
                  </div>

                ) : eventDetails?.type !== "UPCOMING" && eventDetails?.type !== "ENDED" && (

                  <div className="watchNow" onClick={() => watchVideoHandler()}>
                    <button>{subscribeButton ? <Translate textKey={'subscribe'} /> : <Translate textKey={'watch_now'} />}</button>
                  </div>
                )
              }



              <div className="buttons">
                <div className="buttonContainerOne">
                  <button
                    className="share"
                    onClick={() => {
                      setIsShareActive(!isShareActive);
                      setIsReport(false);
                    }}
                    ref={shareIconTwoRef}
                  >
                    <Translate textKey={'share'} />
                  </button>
                  <div className={isShareActive ? "active shareContainer" : "shareContainer"} ref={shareTwoRef}>
                    <div className="facebookContainer" onClick={() => setIsShareActive(false)}>
                      {/* <div className="left"> */}
                      <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                        <Translate textKey={'facebook'} />
                        <div className="icon">
                          <FacebookIcon />
                        </div>
                      </FacebookShareButton>
                      {/* </div> */}
                      {/* <div className="right">
                        <FacebookIcon />
                      </div> */}
                    </div>
                    <div className="twitterContainer" onClick={() => setIsShareActive(false)}>
                      <TwitterShareButton url={pageURL} className="twitter">
                        <Translate textKey={'twitter'} />
                        <div className="icon">
                          <TwitterIcon />
                        </div>
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>
                <div className="buttonContainerTwo">
                  <button
                    className="more"
                    onClick={() => {
                      setIsReport(!isReport);
                      setIsShareActive(false);
                    }}
                    ref={reportTwoRef}
                  >
                    {isReport ? <Closeicon /> : "..."}
                  </button>
                  {isReport ? (
                    <div
                      className={isReport ? "reportProblemContainer active" : "reportProblemContainer"}
                      onClick={() => navigate("/contact-support")}
                    >
                      <span><Translate textKey={'report_problem'} /></span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <p className="description">{eventDetails?.description}</p>

              {!isSubscribedUser || eventDetails?.free_event === true ? (
                eventDetails?.subscriptions?.length > 0 || eventDetails?.free_event === true ? (
                  <div className="subscriptions">
                    {initialVideoSubscriptions?.map((item, index) => (
                      <div className="items" key={index} onClick={() => handleSubscribe(item)}>
                        {item?.subscription_text}
                      </div>
                    ))}
                    {eventDetails?.free_event === true && (
                      <div className="items free" onClick={() => watchVideoHandler()}>
                        <Translate textKey={'free'} />
                      </div>
                    )}
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {
        eventDetails?.type === "ENDED" &&
        <div className="eventEndedOverlay">
          <h2><Translate textKey={'the_event_ended_message'} /></h2>
          <p onClick={showDetailsRedirect}><Translate textKey={'click_here_to_watch'} /></p>
        </div>
      }
    </div>
  )
}

export default EventDetailsScreen
